// SpaceScene.js
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import { Vector3, Quaternion, Matrix4, Euler } from 'three';
import socket from '../services/socket';
import { throttle } from 'lodash';
import CameraControls from './CameraControls';
import SceneLighting from './SceneLighting';
import Planets from './Planets';
import Projectiles from './Projectiles';
import Explosions from './Explosions';
import OtherPlayers from './OtherPlayers'

const SYSTEM_RADIUS = 80000;


const SpaceScene = ({
  isSpaceshipView,
  spaceshipPosition,
  spaceshipRotation,
  setSpaceshipPosition,
  setSpaceshipRotation,
  velocity,
  rotationVelocity,
  setVelocity,
  setRotationVelocity,
  movementSpeed,
  rotationSpeed,
  otherPlayers,
  setOtherPlayers,
  health,
  setHealth,
  hitEffect,
  setHitEffect,
  shoot,
  setCurrentWorld
}) => {
  const { camera } = useThree();
  const [projectiles, setProjectiles] = useState([]);
  const [explosions, setExplosions] = useState([]);
  const [selectedPlanet, setSelectedPlanet] = useState(null);
  const [edgeOpacity, setEdgeOpacity] = useState(0);
  const [solarSystem, setSolarSystem] = useState({ sun: null, planets: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [currentWorld, setCurrentWorldState] = useState('World 1');


  useEffect(() => {
    socket.on("updatePlayers", (players) => {
      setOtherPlayers(players || {});
    });

    socket.on("newProjectile", (projectile) => {
      if (!projectile.position || !projectile.direction) {
        return;
      }
      setProjectiles((prev) => [
        ...prev,
        {
          ...projectile,
          position: new Vector3().fromArray(projectile.position),
          direction: new Vector3().fromArray(projectile.direction),
        },
      ]);
    });

    socket.on("newExplosion", (explosion) => {
      if (!explosion.position) {
        return;
      }
      setExplosions((prev) => [...prev, explosion]);
    });

    socket.on("newSolarSystem", (newSolarSystem, entryState) => {
      setSolarSystem(newSolarSystem);
      setCurrentWorldState(newSolarSystem[0].name);
      setCurrentWorld(newSolarSystem[0].name);
      setSpaceshipPosition(new Vector3().fromArray(entryState.position));
      setSpaceshipRotation(new Quaternion().fromArray(entryState.rotation));
      setIsLoading(false);
    });

    socket.on('spaceshipState', (state) => {
      setSpaceshipPosition(new Vector3().fromArray(state.position));
      setSpaceshipRotation(new Quaternion().fromArray(state.rotation));
    });
    setIsLoading(true);
    socket.emit('generateNewSolarSystem', { coordinates: [0, 0, 0], entryState: { position: [0, 0, 0], rotation: [0, 0, 0, 1] } });

    return () => {
      socket.off("updatePlayers");
      socket.off("newProjectile");
      socket.off("newExplosion");
      socket.off("newSolarSystem");
      socket.off('spaceshipState');
    };
  }, [setOtherPlayers, setSolarSystem, setSpaceshipPosition, setSpaceshipRotation]);

  const emitPlayerState = throttle((newPosition, newRotation) => {
    socket.emit("playerState", {
      position: [newPosition.x, newPosition.y, newPosition.z],
      rotation: [newRotation.x, newRotation.y, newRotation.z, newRotation.w],
      userId: socket.id,
      world: currentWorld
    });
  }, 50);

  const shootProjectile = () => {
    const direction = new Vector3(0, 0, -30).applyQuaternion(spaceshipRotation.clone());
    const projectile = {
      position: spaceshipPosition.clone().add(direction.clone().multiplyScalar(5)),
      direction,
      speed: 10,
      id: Date.now(),
      origin: socket.id // Add this line to track the origin of the projectile
    };
    setProjectiles((prev) => [...prev, projectile]);

    socket.emit('shootProjectile', {
      ...projectile,
      position: projectile.position.toArray(),
      direction: projectile.direction.toArray(),
      origin: socket.id // Add this line to track the origin of the projectile
    });
  };

  const addExplosion = (position) => {
    const explosion = {
      position: position.toArray(),
      color: [1, 0.5, 0],
      size: 5,
      ttl: 200
    };
    setExplosions((prev) => [...prev, explosion]);
    socket.emit('explosion', explosion);
  };

  const calculateEntryPosition = (position, rotation) => {
    let entryPosition = new Vector3();
    let entryRotation = new Quaternion().copy(rotation);

    if (position.x >= SYSTEM_RADIUS) {
      entryPosition.set(-SYSTEM_RADIUS + 10, position.y, position.z);
    } else if (position.x <= -SYSTEM_RADIUS) {
      entryPosition.set(SYSTEM_RADIUS - 10, position.y, position.z);
    }

    if (position.y >= SYSTEM_RADIUS) {
      entryPosition.set(position.x, -SYSTEM_RADIUS + 10, position.z);
    } else if (position.y <= -SYSTEM_RADIUS) {
      entryPosition.set(position.x, SYSTEM_RADIUS - 10, position.z);
    }

    if (position.z >= SYSTEM_RADIUS) {
      entryPosition.set(position.x, position.y, -SYSTEM_RADIUS + 10);
    } else if (position.z <= -SYSTEM_RADIUS) {
      entryPosition.set(position.x, position.y, SYSTEM_RADIUS - 10);
    }

    return {
      position: entryPosition,
      rotation: entryRotation
    };
  };

  const checkCollision = useCallback(() => {
    projectiles.forEach((projectile) => {
      if (!projectile.position) {
        return;
      }
      const distance = projectile.position.distanceTo(spaceshipPosition);
      if (distance < 250 && projectile.origin !== socket.id) { // Check if the projectile origin is not the same as the current player's id
        setHealth((prevHealth) => prevHealth - 20);
        socket.emit('playerHit', socket.id, 20);
        setHitEffect(true);
        setTimeout(() => setHitEffect(false), 200);
        if (health <= 20) {
          const entryPosition = calculateEntryPosition(spaceshipPosition, spaceshipRotation);
          setSpaceshipPosition(entryPosition.position);
          setSpaceshipRotation(entryPosition.rotation);
          setHealth(100);
        }
        addExplosion(projectile.position);
        setProjectiles((prev) => prev.filter((p) => p.id !== projectile.id));
      }
    });
  }, [projectiles, spaceshipPosition, health, setHealth, setHitEffect]);

  useEffect(() => {
    if (shoot) {
      shootProjectile();
    }
  }, [shoot]);

  useFrame(() => {
    if (isSpaceshipView) {
      camera.position.copy(spaceshipPosition);
      camera.quaternion.copy(spaceshipRotation);

      const rotationMatrix = new Matrix4().makeRotationFromQuaternion(spaceshipRotation);

      if (velocity.length() > 0) {
        const direction = velocity.clone().applyMatrix4(rotationMatrix);
        const newPosition = spaceshipPosition.clone().add(direction);
        const distanceFromCenter = newPosition.length();

        if (distanceFromCenter >= SYSTEM_RADIUS) {
          const normalizedPosition = newPosition.normalize().multiplyScalar(SYSTEM_RADIUS - 10);
          const coordinates = [
            Math.round(normalizedPosition.x / SYSTEM_RADIUS),
            Math.round(normalizedPosition.y / SYSTEM_RADIUS),
            Math.round(normalizedPosition.z / SYSTEM_RADIUS)
          ];
          handleWorldChange(coordinates);
          const entryPosition = calculateEntryPosition(spaceshipPosition, spaceshipRotation);
          setSpaceshipPosition(entryPosition.position);
          setSpaceshipRotation(entryPosition.rotation);
        } else {
          setSpaceshipPosition(newPosition);
        }

        emitPlayerState(newPosition, spaceshipRotation);
      }

      if (rotationVelocity.length() > 0) {
        const euler = new Euler(rotationVelocity.x, rotationVelocity.y, 0);
        const rotationChange = new Quaternion().setFromEuler(euler);
        setSpaceshipRotation((prev) => prev.multiply(rotationChange));
      }
    }
  });

  const handlePlanetClick = useCallback((planetName) => {
    setSelectedPlanet(planetName);
  }, []);

  const handleWorldChange = (coordinates) => {
    setIsLoading(true);
    const entryState = calculateEntryPosition(spaceshipPosition, spaceshipRotation);
    socket.emit('generateNewSolarSystem', { coordinates, entryState });
  };

  return (
    <>
      <CameraControls isSpaceshipView={isSpaceshipView} />
      <SceneLighting />
      <Planets handlePlanetClick={handlePlanetClick} solarSystem={solarSystem} />
      <Stars radius={SYSTEM_RADIUS / 2} depth={3} count={50000} factor={3} saturation={10} fade speed={1} />
      <Stars radius={SYSTEM_RADIUS / 1.5} depth={4} count={80000} factor={8} saturation={50} fade speed={1} />
      <Stars radius={SYSTEM_RADIUS / 1.2} depth={5} count={50000} factor={3} saturation={100} fade speed={1} />
      <Stars radius={SYSTEM_RADIUS} depth={50} count={50000} factor={3} saturation={100} fade speed={1} />
      {isSpaceshipView && (
        <mesh position={[0, 0, -1]}>
          <planeGeometry args={[2, 2]} />
          <meshBasicMaterial color="black" transparent opacity={edgeOpacity} />
        </mesh>
      )}

      <OtherPlayers otherPlayers={Object.values(otherPlayers).filter(player => player.world === currentWorld)} />
      <Projectiles projectiles={projectiles} setProjectiles={setProjectiles} checkCollision={checkCollision} />
      <Explosions explosions={explosions} setExplosions={setExplosions} />
    </>
  );
};

export default SpaceScene;
