import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { Vector3, Quaternion } from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { Text } from '@react-three/drei';
import tex1 from '../assets/spaceship/spaceship-panels1-ao.png';
import tex2 from '../assets/spaceship/spaceship-panels1-height.png';

const Spaceship = ({
  initialPosition = [0, 0, 0],
  initialRotation = [0, 0, 0, 1],
  playerId,
  projectiles = [],
  setProjectiles,
  setHealth,
  health,
  setHitEffect,
  addExplosion,
  socket,
  position,
  rotation
}) => {
  const [spaceshipPosition, setSpaceshipPosition] = useState(new Vector3().fromArray(initialPosition));
  const [spaceshipRotation, setSpaceshipRotation] = useState(new Quaternion().fromArray(initialRotation));
  const shipRef = useRef();
  const engineGlowRef = useRef();

  const bodyTexture = useLoader(TextureLoader, tex1);
  const glassTexture = useLoader(TextureLoader, tex2);

  const shipScale = 20;
  const bodySize = [20 * shipScale, 10 * shipScale, 20 * shipScale];
  const cockpitSize = 5 * shipScale;
  const wingSize = [10 * shipScale, 2 * shipScale, 15 * shipScale];
  const engineSize = [2 * shipScale, 3 * shipScale, 5 * shipScale];
  const textSize = 300 * shipScale;
  const textPosition = [0, 7 * shipScale, 0];
  const engineGlowPosition = [0, -5 * shipScale, -10 * shipScale];
  const wingPosition = 12 * shipScale;

  const spaceshipAABB = {
    min: new Vector3(
      spaceshipPosition.x - 10 * shipScale,
      spaceshipPosition.y - 5 * shipScale,
      spaceshipPosition.z - 10 * shipScale
    ),
    max: new Vector3(
      spaceshipPosition.x + 10 * shipScale,
      spaceshipPosition.y + 5 * shipScale,
      spaceshipPosition.z + 10 * shipScale
    )
  };

  const checkAABBCollision = (box1, box2) => {
    return (
      box1.min.x <= box2.max.x && box1.max.x >= box2.min.x &&
      box1.min.y <= box2.max.y && box1.max.y >= box2.min.y &&
      box1.min.z <= box2.max.z && box1.max.z >= box2.min.z
    );
  };

  const checkCollision = useCallback(() => {
    projectiles.forEach((projectile) => {
      if (!projectile.position || projectile.origin === playerId) {
        return;
      }

      const projectileAABB = {
        min: new Vector3(
          projectile.position.x - 7.5,
          projectile.position.y - 7.5,
          projectile.position.z - 7.5
        ),
        max: new Vector3(
          projectile.position.x + 7.5,
          projectile.position.y + 7.5,
          projectile.position.z + 7.5
        )
      };

      if (checkAABBCollision(spaceshipAABB, projectileAABB)) {
        setHealth((prevHealth) => prevHealth - 20);
        socket.emit('playerHit', socket.id, 20);
        setHitEffect(true);
        setTimeout(() => setHitEffect(false), 200);
        if (health <= 20) {
          setSpaceshipPosition(new Vector3(0, 100, 200));
          setSpaceshipRotation(new Quaternion());
          setHealth(100);
        }
        addExplosion(projectile.position);
        setProjectiles((prev) => prev.filter((p) => p.id !== projectile.id));
      }
    });
  }, [projectiles, playerId, spaceshipAABB, health, setHealth, setHitEffect, socket, addExplosion, setProjectiles]);

  useFrame((state, delta) => {
    if (shipRef.current) {
      shipRef.current.position.y += Math.sin(state.clock.elapsedTime * 2) * 0.05;
    }

    if (engineGlowRef.current) {
      engineGlowRef.current.intensity = 1 + Math.sin(state.clock.elapsedTime * 5) * 0.3;
    }
  });

  useEffect(() => {
    if (projectiles.length > 0) {
      checkCollision();
    }
  }, [projectiles]);

  useEffect(() => {
    if (position) {
      setSpaceshipPosition(new Vector3().fromArray(position));
    }
    if (rotation) {
      setSpaceshipRotation(new Quaternion().fromArray(rotation));
    }
  }, [position, rotation]);

  return (
    <group ref={shipRef} position={spaceshipPosition} quaternion={spaceshipRotation}>
      <mesh>
        <boxGeometry args={bodySize} />
        <meshStandardMaterial map={bodyTexture} metalness={0.6} roughness={0.4} />
      </mesh>
      <mesh position={[0, 5 * shipScale, 4 * shipScale]}>
        <sphereGeometry args={[cockpitSize, 32, 32, 0, Math.PI * 2, 0, Math.PI / 2]} />
        <meshPhysicalMaterial map={glassTexture} transparent opacity={0.7} metalness={0.2} roughness={0.1} />
      </mesh>
      <mesh position={[wingPosition, 0, 0]}>
        <boxGeometry args={wingSize} />
        <meshStandardMaterial map={bodyTexture} metalness={0.8} roughness={0.2} />
      </mesh>
      <mesh position={[-wingPosition, 0, 0]}>
        <boxGeometry args={wingSize} />
        <meshStandardMaterial map={bodyTexture} metalness={0.8} roughness={0.2} />
      </mesh>
      <mesh position={[5 * shipScale, -5 * shipScale, -8 * shipScale]}>
        <cylinderGeometry args={engineSize} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <mesh position={[-5 * shipScale, -5 * shipScale, -8 * shipScale]}>
        <cylinderGeometry args={engineSize} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <pointLight ref={engineGlowRef} position={engineGlowPosition} color="#00f" intensity={1.5} distance={20} />
      <Text 
        position={textPosition}
        fontSize={textSize}
        color="#fff"
        anchorX="center"
        anchorY="middle"
      >
        {playerId}
      </Text>
    </group>
  );
};

export default Spaceship;