import React from 'react';
import '../styles/Dashboard.css';
import Leaderboard from './Leaderboard';
import socket from '../services/socket';
import SpaceshipControls from './SpaceshipControls';
import { Link } from 'react-router-dom';
import InstallPrompt from './InstallPrompt';

const Dashboard = ({
  isOpen,
  toggleDashboard,
  userName,
  setUserName,
  connectedUsers,
  leaderboard,
  isSpaceshipView,
  toggleSpaceshipView,
  movementSpeed,
  rotationSpeed,
  setMovementSpeed,
  setRotationSpeed,
  showRadar,
  setShowRadar,
  deferredPrompt,
  handleInstallClick,
  currentWorld
  
}) => {

  const handleToggleRadar = () => {
    setShowRadar(prevShowRadar => !prevShowRadar);
  };

  return (
    <div className={`dashboard ${isOpen ? 'open' : ''}`}>
      {/* Add a button to close the dashboard */}
      <div className="dashboard-content">
      <h5>Current World: {currentWorld}</h5>
        <div className="dashboard-content-top">
          <SpaceshipControls
            isSpaceshipView={isSpaceshipView}
            toggleSpaceshipView={toggleSpaceshipView}
            movementSpeed={movementSpeed}
            rotationSpeed={rotationSpeed}
            setMovementSpeed={setMovementSpeed}
            setRotationSpeed={setRotationSpeed}
          />
          <button className='cool-green-button' onClick={handleToggleRadar}>
            {showRadar ? "Hide Radar" : "Show Radar"}
          </button>
        </div>

        <div className="dashboard-content-bottom">
          <Leaderboard leaderboard={leaderboard} />

          <div className="server_status">
            <p>Server status: {socket.connected ? "Connected" : "Disconnected"}</p>
          </div>

          <div className="connected-users">
            <p>Connected users: {connectedUsers}</p>
          </div>
            {deferredPrompt && (
              <>
                <Link to='/install'>
                  <button onClick={handleInstallClick} className="install-button">
                    Download App
                  </button>
                </Link>
                <InstallPrompt />
              </>
            )}
          </div>
      </div>
    </div>
  );
};

export default Dashboard;
