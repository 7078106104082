import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InstallPage = ({ handleInstallClick }) => {
  const [message, setMessage] = useState('Installing...');
  const navigate = useNavigate();

  useEffect(() => {
    console.log("InstallPage component mounted");
    handleInstallClick();

    const handleAppInstalled = () => {
      console.log("App successfully installed");
      setMessage('wait for it ...');
      setTimeout(() => {
        setMessage('click the pop up to open the app');
      }, 4000); // Redirect after 4 seconds
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, [handleInstallClick, navigate]);

  return (
    <div>
      <h1>{message}</h1>
    </div>
  );
};

export default InstallPage;