// src/components/Leaderboard.js

import React, { useEffect, useState } from 'react';
import socket from '../services/socket';
import '../styles/Leaderboard.css';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    socket.on('leaderboard', (data) => {
      setLeaderboard(data);
    });

    return () => {
      socket.off('leaderboard');
    };
  }, []);

  return (
    <div className="leaderboard">
      <h2>Top Players</h2>
      <ol>
        {leaderboard.map((player, index) => (
          <li key={index} className='player-item'>
            {player.name} :   
            {player.takedowns} takedowns
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Leaderboard;
