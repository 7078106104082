import React, { useRef, useEffect } from 'react';
import { useThree } from '@react-three/fiber'; // Correct import
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Vector3 } from 'three';

const CAMERA_POSITION = new Vector3(0, 100, 200);
const CAMERA_FOV = 70;
const CAMERA_FAR = 800000;
const MAX_ZOOM = 15000;
const INITIAL_ZOOM = 2000;

const CameraControls = ({ isSpaceshipView }) => {
  const controlsRef = useRef();
  const { camera, gl } = useThree();

  useEffect(() => {
    if (!isSpaceshipView && controlsRef.current) {
      controlsRef.current.reset();
    }
    // Set the initial zoom level
    camera.position.set(0, 100, INITIAL_ZOOM);
    camera.updateProjectionMatrix();
  }, [isSpaceshipView, camera]);

  return (
    <>
      <PerspectiveCamera 
      makeDefault 
      position={CAMERA_POSITION} 
      fov={CAMERA_FOV}
      far={CAMERA_FAR} // Add the far property
      
      />
      
      <OrbitControls
        ref={controlsRef}
        enableZoom={true}
        enablePan={true}
        enableRotate={true}
        enabled={!isSpaceshipView}
        args={[camera, gl.domElement]}
        maxDistance={MAX_ZOOM}
      />
    </>
  );
};

export default CameraControls;
