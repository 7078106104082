// SpaceshipControls.js
import './SpaceshipControls.css';
import React, { useEffect } from 'react';

const SpaceshipControls = ({ 
  isSpaceshipView, 
  toggleSpaceshipView, 
  movementSpeed, 
  rotationSpeed,
  setMovementSpeed,
  setRotationSpeed
}) => {
  useEffect(() => {
    toggleSpaceshipView();
  }, [toggleSpaceshipView]);

  return (
    <div className="spaceship-controls-container">
      {isSpaceshipView && (
        <div className="spaceship-controls-info">
          <div className="spaceship-stats">
            <label>
              Speed: {movementSpeed.toFixed(2)}
              <input 
                type="range" 
                min="5" 
                max="100" 
                step="0.1" 
                value={movementSpeed}
                onChange={(e) => setMovementSpeed(Number(e.target.value))}
              />
            </label>
            <label>
              Rotation: {rotationSpeed.toFixed(4)}
              <input 
                type="range" 
                min="0.05" 
                max="150" 
                step="0.0005" 
                value={rotationSpeed}
                onChange={(e) => setRotationSpeed(Number(e.target.value))}
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpaceshipControls;
