import React, { useEffect, useRef } from 'react';
import Sun from './planets/Sun';
import OrbitLine from './planets/OrbitLine';
import Planet from './planets/Planet';
import sunTex from '../assets/tex/2k_sun.jpg';
import {planetData} from './planets/planetData';

const SCALE_FACTOR = 100;
const ORBIT_SPEED_FACTOR = 1 / 100;


const Planets = ({ handlePlanetClick, solarSystem }) => {
  const sunRef = useRef(null);
  const planetsRef = useRef(null);

  const [ranTexArr, setRanTexArr] = React.useState([]);

  
// Function to pick a random planet from planetData
const getRandomPlanetTexture = () => {
  const randomIndex = Math.floor(Math.random() * planetData.length);
  setRanTexArr([...ranTexArr, planetData[randomIndex].texture]);
  
};

  useEffect(() => {
    console.log("solarSystem");
    console.log(solarSystem);
    getRandomPlanetTexture();

    if (solarSystem) {
      if (solarSystem.length > 0) {
        console.log("true", solarSystem);
        sunRef.current = solarSystem[0].sun;
        planetsRef.current = solarSystem[0].planets;
        var multi = 1
        var changin = 1
        planetsRef.current.forEach((planet) => {
          multi += 2;
          changin += 100;
          console.log(planet.SemiMajorAxisAU);
          planet.SemiMajorAxisAU = planet.SemiMajorAxisAU * multi*2;
          planet.RadiusJpt = planet.RadiusJpt * multi;
          planet.PeriodDays = planet.PeriodDays * multi;
          planet.Inclination = planet.Inclination * changin;
          planet.Obliquity = planet.Obliquity * multi;
          planet.SurfaceTempK = planet.SurfaceTempK * multi;
          console.log(planet.inclination);

        });
      }
    }
  }, [solarSystem]);

  return (
    <>
      {sunRef.current && (
        <Sun position={[0, 0, 0]} size={sunRef.current.RadiusJpt * SCALE_FACTOR*10} texture={sunTex} />
      )}
      {planetsRef.current &&
        planetsRef.current.map((planet, index =1) => (
          <React.Fragment key={index}>
            <Planet
              size={planet.RadiusJpt * SCALE_FACTOR * 400}
              texture={ranTexArr[index] || sunTex} // Use random texture
              rotationSpeed={(1 / (planet.PeriodDays || 1)) * 0.01}
              orbitSpeed={(1 / (planet.PeriodDays || 1)) * ORBIT_SPEED_FACTOR * 100}
              distanceFromSun={planet.SemiMajorAxisAU * SCALE_FACTOR / 40}
              name={`Planet ${index + 1}`}
              orbitalInclination={planet.SemiMajorAxisAU} // Update this with the correct value if available
              obliquityToOrbit={0} // Update this with the correct value if available
              onClick={() => handlePlanetClick(`Planet ${index + 1}`)}
              hasRings={false} // Update this with the correct value if available
              numberOfMoons={0} // Update this with the correct value if available
              surfaceGravity={0} // Update this with the correct value if available
              meanTemperature={planet.SurfaceTempK}
              orbitalEccentricity={planet.Eccentricity/2}
            />
            <OrbitLine
              sunPosition={[0, 0, 0]}
              distance={planet.SemiMajorAxisAU * SCALE_FACTOR / 40}
              eccentricity={planet.Eccentricity/2}
              inclination={planet.SemiMajorAxisAU} // Update this with the correct value if available
            />
          </React.Fragment>
        ))}
    </>
  );
};

export default Planets;