import React from 'react';
import { useFrame } from '@react-three/fiber';

const Projectiles = ({ projectiles, setProjectiles, checkCollision }) => {
  useFrame(() => {
    setProjectiles((prev) => prev.map(p => {
      if (!p.position || !p.direction) {
        return p;
      }
      return {
        ...p,
        position: p.position.clone().add(p.direction.clone().multiplyScalar(p.speed))
      };
    }));
    checkCollision();
  });

  return (
    <>
      {projectiles.map((projectile, index) => {
        if (!projectile.position) {
          return null;
        }
        return (
          <mesh key={index} position={projectile.position}>
            <sphereGeometry args={[7.5, 16, 16]} />
            <meshBasicMaterial color="red" />
          </mesh>
        );
      })}
    </>
  );
};

export default Projectiles;
