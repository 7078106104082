import { text } from "d3";
import { texture } from "three/examples/jsm/nodes/Nodes.js";

import mercuryTex from '../../assets/tex/2k_mercury.jpg';
import venusTex from '../../assets/tex/2k_venus_surface.jpg'; 
import earthTex from '../../assets/tex/2k_earth_daymap.jpg';
import marsTex from '../../assets/tex/2k_mars.jpg';
import jupiterTex from '../../assets/tex/2k_jupiter.jpg';
import saturnTex from '../../assets/tex/2k_saturn.jpg';
import uranusTex from '../../assets/tex/2k_uranus.jpg';
import neptuneTex from '../../assets/tex/2k_neptune.jpg';


export const planetData = [
    {
      name: "Mercury",
      texture: mercuryTex,
      color: "Grey",
      diameter: 150,
      distanceFromSun: 57.9,
      orbitalPeriod: 88,
      rotationPeriod: 1407.6,
      mass: 0.33,
      density: 5429,
      gravity: 3.7,
      moons: 0,
      hasRings: false,
      meanTemperature: 167,
      orbitalInclination: 7,
      obliquityToOrbit: 0.034,
      escapeVelocity: 4.3,
      surfacePressure: 0,
      hasGlobalMagneticField: false,
      numberOfMoons: 0,
      orbitalEccentricity: 0.206,
      surfaceGravity: 3.7,
      perihelion: 46,
      

    },
    {
        name: "Venus",
        texture: venusTex,
        color: "Brown and Grey",
        mass: 4.87,
        diameter: 200,
        density: 5243,
        surfaceGravity: 8.9,
        escapeVelocity: 10.4,
        rotationPeriod: -5832.5,
        lengthOfDay: 2802,
        distanceFromSun: 108.2,
        perihelion: 107.5,
        aphelion: 108.9,
        orbitalPeriod: 224.7,
        orbitalVelocity: 35,
        orbitalInclination: 3.4,
        orbitalEccentricity: 0.007,
        obliquityToOrbit: 177.4,
        meanTemperature: 464,
        surfacePressure: 92,
        numberOfMoons: 0,
        hasRingSystem: false,
        hasGlobalMagneticField: false
    },
    {
            name: "Earth",
            texture: earthTex,
            color: "Blue, Brown Green and White",
            mass: 5.97,
            diameter: 250,
            density: 5514,
            surfaceGravity: 9.8,
            escapeVelocity: 11.2,
            rotationPeriod: 23.9,
            lengthOfDay: 24,
            distanceFromSun: 149.6,
            perihelion: 147.1,
            aphelion: 152.1,
            orbitalPeriod: 365.2,
            orbitalVelocity: 29.8,
            orbitalInclination: 0,
            orbitalEccentricity: 0.017,
            obliquityToOrbit: 23.4,
            meanTemperature: 15,
            surfacePressure: 1,
            numberOfMoons: 1,
            hasRingSystem: false,
            hasGlobalMagneticField: true
          
    },
{
        name: "Mars",
        texture: marsTex,
        color: "Red, Brown and Tan",
        mass: 0.642,
        diameter: 400,
        density: 3934,
        surfaceGravity: 3.7,
        escapeVelocity: 5,
        rotationPeriod: 24.6,
        lengthOfDay: 24.7,
        distanceFromSun: 228,
        perihelion: 206.7,
        aphelion: 249.3,
        orbitalPeriod: 687,
        orbitalVelocity: 24.1,
        orbitalInclination: 1.8,
        orbitalEccentricity: 0.094,
        obliquityToOrbit: 25.2,
        meanTemperature: -65,
        surfacePressure: 0.01,
        numberOfMoons: 2,
        hasRingSystem: false,
        hasGlobalMagneticField: false
      },
       {
        name: "Jupiter",
        texture: jupiterTex,
        color: "Brown, Orange and Tan, with White cloud stripes",
        mass: 1898,
        diameter: 600,
        density: 1326,
        surfaceGravity: 23.1,
        escapeVelocity: 59.5,
        rotationPeriod: 9.9,
        lengthOfDay: 9.9,
        distanceFromSun: 778.5,
        perihelion: 740.6,
        aphelion: 816.4,
        orbitalPeriod: 4331,
        orbitalVelocity: 13.1,
        orbitalInclination: 1.3,
        orbitalEccentricity: 0.049,
        obliquityToOrbit: 3.1,
        meanTemperature: -110,
        surfacePressure: null, // Unknown in the data
        numberOfMoons: 79,
        hasRingSystem: true,
        hasGlobalMagneticField: true
      },
      {
        name: "Saturn",
        texture: saturnTex,
        color: "Golden, Brown, and Blue-Grey",
        mass: 568,
        diameter: 2205,
        density: 687,
        surfaceGravity: 9,
        escapeVelocity: 35.5,
        rotationPeriod: 10.7,
        lengthOfDay: 10.7,
        distanceFromSun: 1432,
        perihelion: 1357.6,
        aphelion: 1506.5,
        orbitalPeriod: 10747,
        orbitalVelocity: 9.7,
        orbitalInclination: 2.5,
        orbitalEccentricity: 0.052,
        obliquityToOrbit: 26.7,
        meanTemperature: -140,
        surfacePressure: null, // Unknown in the data
        numberOfMoons: 82,
        hasRingSystem: true,
        hasGlobalMagneticField: true
      },
      {
        name: "Uranus",
        texture: uranusTex,
        color: "Blue-Green",
        mass: 86.8,
        diameter: 800,
        density: 1270,
        surfaceGravity: 8.7,
        escapeVelocity: 21.3,
        rotationPeriod: -17.2,
        lengthOfDay: 17.2,
        distanceFromSun: 2867,
        perihelion: 2732.7,
        aphelion: 3001.4,
        orbitalPeriod: 30589,
        orbitalVelocity: 6.8,
        orbitalInclination: 0.8,
        orbitalEccentricity: 0.047,
        obliquityToOrbit: 97.8,
        meanTemperature: -195,
        surfacePressure: null, // Unknown in the data
        numberOfMoons: 27,
        hasRingSystem: true,
        hasGlobalMagneticField: true
      },
       {
        name: "Neptune",
        texture: neptuneTex,
        color: "Blue",
        mass: 102,
        diameter: 650,
        density: 1638,
        surfaceGravity: 11,
        escapeVelocity: 23.5,
        rotationPeriod: 16.1,
        lengthOfDay: 16.1,
        distanceFromSun: 4515,
        perihelion: 4471.1,
        aphelion: 4558.9,
        orbitalPeriod: 59800,
        orbitalVelocity: 5.4,
        orbitalInclination: 1.8,
        orbitalEccentricity: 0.01,
        obliquityToOrbit: 28.3,
        meanTemperature: -200,
        surfacePressure: null, // Unknown in the data
        numberOfMoons: 14,
        hasRingSystem: true,
        hasGlobalMagneticField: true
      },
      
  ];