import React, { useEffect } from 'react';
import { Html } from '@react-three/drei';
import Spaceship from './Spaceship';

const OtherPlayers = ({ otherPlayers }) => {

  useEffect(() => {
  }, [otherPlayers]);
  
  return (
    <>
      {Object.values(otherPlayers).map((player, index) => {
        if (!player.position || player.position.length < 3) {
          return null;
        }
        return (
          <React.Fragment key={index}>
            <Spaceship 
              position={player.position} 
              rotation={player.rotation} 
              playerId={player.id} 
            />
            <Html position={[player.position[0], player.position[1] + 20, player.position[2]]} distanceFactor={10}>
              <div className="player-name" style={{ transform: 'translate(-50%, -100%)', color: 'white', fontSize: '15em', backgroundColor: "rgba(255,100,200,0.4)", padding: '2px 5px', borderRadius: '5px' }}>
                {player.name}
              </div>
            </Html>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default OtherPlayers;