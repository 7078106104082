// JoystickControls.js
import React from 'react';
import { Joystick } from 'react-joystick-component';

const JoystickControls = ({ onMove, onStop }) => {
  return (
    <div className="joystick-controls">
      <Joystick
        size={100}
        baseColor="rgba(255, 255, 255, 0.5)" // White color with 50% transparency
        stickColor="rgba(0, 0, 0, 0.5)" 
        stickSize={40}
        move={onMove}
        stop={onStop}
      />
    </div>
  );
};

export default JoystickControls;
