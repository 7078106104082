// HomePage.js
import React, { useState, useEffect, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { Vector3, Quaternion } from 'three';
import socket from '../services/socket';
import SpaceScene from './SpaceScene';
import Radar from './Radar';
import NamePicker from './NamePicker';
import '../styles/HomePage.css';
import ErrorBoundary from './ErrorBoundary';
import Dashboard from './Dashboard';
import { TbRocket, TbRocketOff } from "react-icons/tb";
import JoystickControls from './JoystickControls';

import localforage from 'localforage';

import { auth } from '../firebase'; // Import Firebase auth

const HomePage = () => {
  const [isSpaceshipView, setIsSpaceshipView] = useState(false);
  const [spaceshipPosition, setSpaceshipPosition] = useState(() => new Vector3(0, 100, 2000));
  const [spaceshipRotation, setSpaceshipRotation] = useState(() => new Quaternion());
  const [velocity, setVelocity] = useState(new Vector3());
  const [rotationVelocity, setRotationVelocity] = useState(new Vector3());
  const [movementSpeed, setMovementSpeed] = useState(20);
  const [rotationSpeed, setRotationSpeed] = useState(0.01);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [otherPlayers, setOtherPlayers] = useState({});
  const [userName, setUserName] = useState('');
  const [health, setHealth] = useState(100);
  const [hitEffect, setHitEffect] = useState(false);
  const [isPvPMode, setIsPvPMode] = useState(true);
  const [connectedUsers, setConnectedUsers] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showRadar, setShowRadar] = useState(true);
  const [shoot, setShoot] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const [downloadingWorlds, setDownloadingWorlds] = useState([]);
  const [cachedWorlds, setCachedWorlds] = useState([]);

  const [currentWorld, setCurrentWorld] = useState('World 1');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsSubmitted(true);
        setUserName(user.displayName || user.email); // Set the user's name or email
      } else {
        setIsSubmitted(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {}, [otherPlayers]);
  useEffect(() => {}, [spaceshipPosition, spaceshipRotation]);
  useEffect(() => { console.log("galaxy:", currentWorld); }, [currentWorld]);

  const generateSolarSystem = async (coordinates) => {
    const key = coordinates.join(',');
    let solarSystem = await localforage.getItem(key);

    if (!solarSystem) {
      setDownloadingWorlds((prev) => [...prev, key]);
      const response = await fetch(`/generateSolarSystem?coordinates=[${coordinates}]`);
      if (response.ok) {
        solarSystem = await response.json();
        await localforage.setItem(key, solarSystem);
        setCachedWorlds((prev) => [...prev, key]);
      } else {
        console.error('Failed to generate or retrieve solar system:', response.statusText);
      }
      setDownloadingWorlds((prev) => prev.filter(world => world !== key));
    }
    return solarSystem;
  };

  useEffect(() => {
    const initializeSolarSystem = async () => {
      const initialCoordinates = [0, 0, 0];
      const solarSystem = await generateSolarSystem(initialCoordinates);
      setCurrentWorld(solarSystem[0].name);
    };

    initializeSolarSystem();
  }, []);

  const toggleDashboard = () => {
    setIsDashboardOpen(!isDashboardOpen);
  };

  const toggleSpaceshipView = useCallback(() => {
    setIsSpaceshipView(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  useEffect(() => {
    socket.on("updatePlayers", (players) => {
      setOtherPlayers(players || {});
    });

    socket.on("connectedUsers", (count) => {
      setConnectedUsers(count || 0);
    });

    socket.on('leaderboard', (data) => {
      setLeaderboard(data);
    });

    return () => {
      socket.off("updatePlayers");
      socket.off("connectedUsers");
      socket.off("leaderboard");
    };
  }, []);

  useEffect(() => {
    if (userName) {
      socket.emit('playerState', {
        position: [spaceshipPosition.x, spaceshipPosition.y, spaceshipPosition.z],
        rotation: [spaceshipRotation.x, spaceshipRotation.y, spaceshipRotation.z, spaceshipRotation.w],
        name: userName
      });
    }
  }, [userName, spaceshipPosition, spaceshipRotation]);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleJoystickMove = (event) => {
    const { x, y } = event;
    const direction = new Vector3(x, 0, -y).normalize().multiplyScalar(movementSpeed);
    setSpaceshipPosition(direction)
    setVelocity(direction);
  };

  const handleJoystickStop = () => {
    setVelocity(new Vector3());
  };

  const handleRotationJoystickMove = (event) => {
    const { x, y } = event;
    const rotationChange = new Vector3(y, -x, 0).normalize().multiplyScalar(rotationSpeed);
    setRotationVelocity(rotationChange);
  };

  const handleRotationJoystickStop = () => {
    setRotationVelocity(new Vector3());
  };

  const handleShoot = (event) => {
    event.preventDefault();
    setShoot(true);
    setTimeout(() => setShoot(false), 100);
  };

  return (
    <div className="home">
      <div className="intro-visual" aria-label="Interactive 3D space scene">
        <ErrorBoundary>
          <Canvas>
            <SpaceScene
              isSpaceshipView={isSpaceshipView}
              spaceshipPosition={spaceshipPosition}
              spaceshipRotation={spaceshipRotation}
              setSpaceshipPosition={setSpaceshipPosition}
              setSpaceshipRotation={setSpaceshipRotation}
              velocity={velocity}
              setVelocity={setVelocity}
              rotationVelocity={rotationVelocity}
              setRotationVelocity={setRotationVelocity}
              movementSpeed={movementSpeed}
              rotationSpeed={rotationSpeed}
              otherPlayers={otherPlayers}
              setOtherPlayers={setOtherPlayers}
              health={health}
              setHealth={setHealth}
              hitEffect={hitEffect}
              setHitEffect={setHitEffect}
              isPvPMode={isPvPMode}
              shoot={shoot}
              setCurrentWorld={setCurrentWorld}
            />
          </Canvas>
        </ErrorBoundary>
      </div>

      <Dashboard
        isOpen={isDashboardOpen}
        toggleDashboard={toggleDashboard}
        userName={userName}
        setUserName={setUserName}
        connectedUsers={connectedUsers}
        leaderboard={leaderboard}
        isSpaceshipView={isSpaceshipView}
        toggleSpaceshipView={toggleSpaceshipView}
        movementSpeed={movementSpeed}
        rotationSpeed={rotationSpeed}
        setMovementSpeed={setMovementSpeed}
        setRotationSpeed={setRotationSpeed}
        showRadar={showRadar}
        setShowRadar={setShowRadar}
        deferredPrompt={deferredPrompt} // Pass the deferredPrompt to Dashboard
        handleInstallClick={handleInstallClick} // Pass the handleInstallClick to Dashboard
        currentWorld={currentWorld}
      />
      {!isSubmitted && 
        <NamePicker
          userName={userName}
          setUserName={setUserName}
          setIsSubmitted={setIsSubmitted}
          deferredPrompt={deferredPrompt} // Pass the deferredPrompt to Dashboard
          handleInstallClick={handleInstallClick} // Pass the handleInstallClick to Dashboard
        />
      }
      {isSubmitted && (
        <div>
          {hitEffect && <div className="hit-effect"></div>}
          {showRadar && !isDashboardOpen &&
            <div className="upper-right">
              <Radar
                playerPosition={spaceshipPosition}
                otherPlayers={otherPlayers}
                userName={userName}
                playerRotation={spaceshipRotation}
              />
            </div>
          }
          <div className="crosshair">
            <div className="crosshair-line"></div>
            <div className="crosshair-line"></div>
          </div>
          <div className='bottom-left'>
            <JoystickControls
              onMove={handleJoystickMove}
              onStop={handleJoystickStop}
            />
          </div>
          <div className='bottom-right'>
            <JoystickControls
              onMove={handleRotationJoystickMove}
              onStop={handleRotationJoystickStop}
            />
          </div>
          <div className="health-bar">
            <div className="health-bar-inner" style={{ width: `${health}%` }}></div>
          </div>
          {!isDashboardOpen &&
            <>
              <button
                className="open-dashboard-btn"
                onClick={toggleDashboard}
              >
                <TbRocket />
              </button>
              <button
                className="shoot-btn-right"
                onPointerDown={handleShoot} // Use pointer events
                onPointerUp={handleShoot} // Use pointer events
              >
                Pew
              </button>
              <button
                className="shoot-btn-left"
                onPointerDown={handleShoot} // Use pointer events
                onPointerUp={handleShoot} // Use pointer events
              >
                Pew
              </button>
            </>
          }
          {isDashboardOpen &&
            <>
              <button className="open-dashboard-btn" onClick={toggleDashboard}>
                <TbRocketOff />
              </button>
            </>
          }
        </div>
      )}
    </div>
  );
};

export default HomePage;
