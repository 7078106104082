import React from 'react';
import { Vector3, Color } from 'three';
import { useFrame } from '@react-three/fiber';

const Explosions = ({ explosions, setExplosions }) => {
  const explosionScale = 15.5;
  useFrame(() => {
    setExplosions((prev) => prev.filter((explosion) => {
      explosion.ttl -= 16;
      return explosion.ttl > 0;
    }));
  });

  return (
    <>
      {explosions.map((explosion, index) => (
        <mesh key={`${index}_${explosion.id}`} position={new Vector3().fromArray(explosion.position)}>
          <sphereGeometry args={[10 * explosionScale, 32 * explosionScale, 32 * explosionScale]} />
          <meshBasicMaterial color={new Color(...explosion.color)} transparent opacity={0.5} />
        </mesh>
      ))}
    </>
  );
};

export default Explosions;