import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import ErrorBoundary from './components/ErrorBoundary';
import HomePage from './components/HomePage';
import './styles/App.css';
import socket from './services/socket';
import LoginPage from './components/auth';
import Dashboard from './components/Dashboard'; // Import the Dashboard component
import InstallPage from './components/InstallPage'; // Import the InstallPage component

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [setUserName, setSetUserName] = useState(false); // Add state for setUserName
  const [deferredPrompt, setDeferredPrompt] = useState(null); // State for the install prompt event

  // Scroll down to hide the URL bar
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  useEffect(() => {
    const onConnect = () => {
      console.log("Connected to the server");
      setIsConnected(true);
    };

    const onDisconnect = () => {
      console.log("Disconnected from the server");
      setIsConnected(false);
    };

    socket.connect();

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.disconnect();
    };
  }, []);

  // Capture the beforeinstallprompt event
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
      setDeferredPrompt(e); // Stash the event so it can be triggered later.
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <Router>
      <div className="App">
        {setUserName && <Navigation />} {/* Conditionally render Navigation */}
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Login" element={<LoginPage />} />
           
            <Route 
              path="/install" 
              element={<InstallPage handleInstallClick={handleInstallClick} />} 
            />
          </Routes>
        </ErrorBoundary>
        <div className="server_status">
          <p>Server status: {isConnected ? "Connected" : "Disconnected"}</p>
        </div>
      </div>
    </Router>
  );
}

export default App;